/* ChartContainer--------------------------------------------------- */
.orgchart-container.custom {
    box-sizing: border-box;
    position: relative;
    margin: 0;
    min-height: calc(100vh - 440px); height: calc(100vh - 200px);
    background-color: #fff;
    border: 2px solid #DDD; 
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}

.orgchart.custom { background: none; border: 0px solid rgb(245, 13, 13) ; }

/* ChartNode--------------------------------------------------------- */
.orgchart.custom ul {display: flex; justify-content: center; border: 0px dashed  rgb(48, 13, 245);}
.orgchart.custom > ul > li > ul li::before {  border-top-color: rgba(33, 90, 136, 1);}
.orgchart.custom > ul > li > ul li .oc-node::before, 
.orgchart.custom ul li .oc-node:not(:only-child)::after { background-color: rgba(33, 90, 136, 1); }

.orgchart.custom ul li .oc-node{ background: none; border: 2px dashed transparent}
.orgchart.custom ul li .oc-node.allowedDrop {border: 2px dashed rgba(68, 157, 68, 0.9) }
.orgchart.custom ul li .oc-node.currentDropTarget {border: 2px dashed  rgba(68, 157, 68, 0.9) }
.orgchart.custom ul li .oc-node.selected {  background-color: rgba(238, 217, 54, 0.5);}
.orgchart.custom ul li .oc-node:hover {  background-color: rgba(238, 217, 54, 0.5);}

/* ฺAmp new style --------------------------------------------------------- */
.orgchart.custom .oc-node .oc-card-tag {  
  position: absolute; bottom: 0px; right:0px;
 
  width: 0px; height: 0px;
  	
  border-radius: 0 0 5px 0;
  border-width: 8px ;
  border-style: solid;
  border-color: rgba(139,139,139,0) rgba(139,139,139,0) rgba(139,139,139,0) rgba(139,139,139,0);
}


.orgchart .pipeline1 .title { background-color: #006699; }
.orgchart .pipeline1 .content { border-color: #006699; }

