.personTooltip {text-align: center; border-width: 3px;}
.personTooltip .personTooltip-content {
  /* opacity: 0;  */
  visibility: hidden; pointer-events: none;
  position: absolute; z-index: 1;
  min-width: 200px;
  
  /* top: 100%; ให้ tooltip แสดงด้านล่าง,  bottom: 100%; ให้ tooltip แสดงด้านบน*/
  /* แสดงขวาล่าง */
  /* top: 100%; left: 50%;    */
   /* เลื่อนมาตรงกลาง */
  /* transform: translate(-50%,0); -webkit-transform: translate(-50%,0); */
  /* ด้านข้าง */
  top: -5px; left: 105%;
  
  margin: 8px 8px 8px 0px; padding: 10px; 
  

  /* text-align: right; */
  /* font-size: 14px; */
  /* line-height: 30px; */
  /* -webkit-box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background:rgba(7, 7, 7,0.8);
  color: #fff;
  border-radius: 5px;
}
.personTooltip:hover .personTooltip-content { 
  /* opacity: 1; pointer-events: auto;  */
  visibility: visible; pointer-events: auto;
}

.personTooltip:hover .personTooltip-content::after { 
  content: " ";
  position: absolute;
  top: 15px; right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color:  transparent rgba(7, 7, 7,0.8) transparent transparent;
}




